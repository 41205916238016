var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":_vm.createwiki}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',[_c('h3',[_vm._v("Site Name "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("The main name of your site")]),_c('br'),_c('span',[_vm._v("Will appear in your page titles and can be changed at any time")]),_c('br'),_c('span',[_vm._v("In MediaWiki terms this is $wgSitename")]),_c('br')])],1),_c('v-text-field',{attrs:{"id":"inputSiteName","prepend-icon":"mdi-format-title","name":"sitename","label":"E.g., Goat Collective","disabled":_vm.inFlight,"error-messages":_vm.error['sitename']},model:{value:(_vm.sitename),callback:function ($$v) {_vm.sitename=$$v},expression:"sitename"}}),_c('h3',[_vm._v("Site Domain "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("A domain name is the site address people type into their browser to visit your site")]),_c('br'),_c('span',[_vm._v("If you already own a domain you can use it by selecting \"Custom Domain\"")]),_c('br'),_c('span',[_vm._v("If not you can use a \"Free Subdomain\"")]),_c('br'),_c('span',[_vm._v("The subdomain must be at least five characters long, and can consist of small Latin letters, digits and hyphens")]),_c('br')])],1),_c('v-radio-group',{attrs:{"row":"","mandatory":true},model:{value:(_vm.domainRadioChoice),callback:function ($$v) {_vm.domainRadioChoice=$$v},expression:"domainRadioChoice"}},[_c('v-radio',{attrs:{"label":"Free Subdomain","value":"sub"}}),_c('v-radio',{attrs:{"label":"Custom Domain","value":"own"}})],1),(_vm.domainRadioChoice === 'sub')?_c('v-text-field',{attrs:{"id":"inputSubdomain","prepend-icon":"mdi-web","name":"subdomain","label":"E.g. goat-collective","suffix":_vm.SUBDOMAIN_SUFFIX,"disabled":_vm.inFlight,"error-messages":_vm.error['siteaddress']},model:{value:(_vm.subdomain),callback:function ($$v) {_vm.subdomain=$$v},expression:"subdomain"}}):_vm._e(),(_vm.domainRadioChoice === 'own')?_c('v-text-field',{attrs:{"id":"inputDomain","prepend-icon":"mdi-web","name":"domain","label":"E.g. goat-collective.com","disabled":_vm.inFlight,"error-messages":_vm.error['siteaddress']},model:{value:(_vm.domain),callback:function ($$v) {_vm.domain=$$v},expression:"domain"}}):_vm._e(),(_vm.domainRadioChoice === 'own')?_c('p',[_vm._v("This domain should have a CNAME record pointing to:")]):_vm._e(),(_vm.domainRadioChoice === 'own')?_c('p',[_vm._v("\""+_vm._s(_vm.CNAME_RECORD)+"\"")]):_vm._e(),_c('h3',[_vm._v("Your User "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Choose the username of your user and the first admin user on the site")]),_c('br'),_c('span',[_vm._v("You will receive an email with log in details for this account")]),_c('br')])],1),_c('v-text-field',{attrs:{"id":"inputUsername","prepend-icon":"mdi-account","name":"username","label":"E.g., Addshore","disabled":_vm.inFlight,"error-messages":_vm.error['username']},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('h3',[_vm._v("Terms of Use")]),_c('v-checkbox',{attrs:{"disabled":_vm.inFlight,"error-messages":_vm.error['terms']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to the "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"/terms-of-use"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Terms of Use ")])]}}])},[_vm._v(" Opens in new window ")]),_vm._v(" . ")],1)]},proxy:true}]),model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":_vm.inFlight}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }